.button {
  appearance: none;
  border: none;
  height: 36px;
  padding: 0 var(--spacer-small);
  border-radius: var(--border-radius-medium);
  font-weight: 500;
  position: relative;

  &:focus {
    outline: 2px solid var(--color-teal-dark);
    outline-offset: 2px;
  }

  &.level-primary {
    background: var(--color-primary);
    color: var(--color-secondary);

    &:not([disabled]) {
      cursor: pointer;

      &:hover {
        background: var(--color-primary-dark);
      }
    }
  }

  &.level-secondary {
    background: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);

    &:not([disabled]) {
      cursor: pointer;

      &:hover {
        background: var(--button-secondary-background-hover-color);
      }
    }
  }

  &.level-destructive {
    color: var(--color-neutral-lightest);
    background-color: var(--color-ruby);

    &:not([disabled]) {
      cursor: pointer;
      &:hover {
        background-color: var(--color-ruby-dark);
      }

      &:focus-visible {
        background-color: var(--color-ruby);
        box-shadow: 0 0 0 1px var(--color-ruby-darkest);
      }

      &:active {
        background-color: var(--color-ruby-dark);
      }
    }
  }

  &.level-primary,
  &.level-secondary,
  &.level-destructive {
    &:not(.is-processing) {
      &[disabled] {
        background: var(--color-neutral);
        color: var(--color-neutral-dark);
      }
    }
  }

  &.is-processing {
    color: transparent;

    .spinner {
      left: 50%;
      position: absolute !important;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
