.link {
  text-decoration: none;
  background: none;
  border: none;
  color: var(--color-aqua-dark);
  cursor: pointer;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  &--disabled {
    color: var(--color-neutral-dark);
    cursor: initial;
  }

  &--small {
    font-size: 12px;
  }

  &--muted {
    color: var(--color-neutral-darkest);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
