.terms {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-color: var(--color-neutral-light);

  .header {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: inset 0px -1px 0px var(--color-neutral);

    .title {
      margin: var(--spacer-regular) 0;
    }
  }

  .body {
    max-width: 600px;
    box-sizing: content-box;
    background-color: var(--color-white);

    margin: var(--spacer-medium) var(--spacer-regular) var(--spacer-biggest) var(--spacer-regular);
    padding: var(--spacer-big) var(--spacer-medium);

    border: 1px solid var(--color-neutral-dark);
    border-radius: var(--border-radius-medium);

    h1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: calc(var(--spacer-regular) * -1);
    }

    p {
      margin: var(--spacer-regular) 0;
      line-height: 21px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
