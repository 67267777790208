@import '@teamleader/ahoy/es/index.css';

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

:root {
  --header-height: 60px;
  --mobile-header-height: 72px;

  --spinner-border-width: 2px;
  --spinner-size-small: calc(1.4 * var(--unit));
  --spinner-size-medium: calc(2.2 * var(--unit));

  --button-secondary-background-color: #f3f3f3;
  --button-secondary-text-color: #1b1e22;
  --button-secondary-background-hover-color: var(--color-neutral);

  --color-primary: var(--color-mint);
  --color-primary-dark: var(--color-mint-dark);
  --color-primary-darkest: var(--color-mint-darkest);

  --color-secondary: var(--color-white);

  --color-menu-dark: var(--color-teal-dark);
  --color-menu-darkest: var(--color-teal-darkest);
  --color-menu-highlighted: #344455;
  --color-menu-text: var(--color-white);
  --color-menu-borders: color-mod(var(--color-white) a(15%));
  --color-menu-alt-text: var(--color-teal-light);

  --group-color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: var(--font-family-inter);
  background: var(--color-white);
  min-width: 320px;
}

button,
p,
input,
textarea {
  font-family: var(--font-family-inter);
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
}

/* Ios zooms in on inputs with a font smaller than 16px */
input,
textarea {
  font-size: 16px;
}

/* Remove shadow on input elements (ios) */
textarea,
input:matches(
    [type='email'],
    [type='number'],
    [type='password'],
    [type='search'],
    [type='tel'],
    [type='text'],
    [type='url']
  ) {
  -webkit-appearance: none;
}

.app {
  min-height: 100vh;
  height: 0px; /* Force IE to respect the min-height */
}
