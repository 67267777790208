.box {
  margin: 0;
  padding: 0;
}

@each $display in (inline, inline-block, block, flex, inline-flex, none) {
  .display-$(display) {
    display: $display;
  }
}

@each $direction in (row, row-reverse, column, column-reverse) {
  .flex-direction-$(direction) {
    flex-direction: $direction;
  }
}

@each $item-alignment in (center, flex-start, flex-end, baseline, stretch) {
  .align-items-$(item-alignment) {
    align-items: $item-alignment;
  }
}

@each $justify in (center, flex-start, flex-end, space-around, space-between, space-evenly) {
  .justify-content-$(justify) {
    justify-content: $justify;
  }
}

/* prettier-ignore */
@each
  $factor, $spacer in (1, 2, 3, 4, 5, 6, 7, 8), (var(--spacer-smallest), var(--spacer-smaller), var(--spacer-small), var(--spacer-regular), var(--spacer-medium), var(--spacer-big), var(--spacer-bigger), var(--spacer-biggest)) {
  .margin-bottom-$(factor) {
    margin-bottom: $spacer;
  }

  .margin-left-$(factor) {
    margin-left: $spacer;
  }

  .margin-right-$(factor) {
    margin-right: $spacer;
  }

  .margin-top-$(factor) {
    margin-top: $spacer;
  }

  .margin-bottom-negative-$(factor) {
    margin-bottom: calc($spacer * -1);
  }

  .margin-left-negative-$(factor) {
    margin-left: calc($spacer * -1);
  }

  .margin-right-negative-$(factor) {
    margin-right: calc($spacer * -1);
  }

  .margin-top-negative-$(factor) {
    margin-top: calc($spacer * -1);
  }

  .padding-bottom-$(factor) {
    padding-bottom: $spacer;
  }

  .padding-left-$(factor) {
    padding-left: $spacer;
  }

  .padding-right-$(factor) {
    padding-right: $spacer;
  }

  .padding-top-$(factor) {
    padding-top: $spacer;
  }
}
