.loader {
  width: 100%;
  height: 100vh; /* Fix height in IE */
  display: flex;

  &--mobile {
    flex-direction: column;
  }

  svg {
    box-sizing: content-box;
  }

  .navigation {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-menu-dark);

    .menu-item {
      box-shadow: inset 0px -1px 0px var(--color-menu-darkest);

      &--selected {
        background: var(--color-menu-darkest);
      }
    }

    .brand {
      margin-top: auto;
      padding: 31px;
    }

    &--collapsed {
      width: 90px;
    }

    &--mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;

      .brand {
        margin-top: 0;
        padding: var(--spacer-small) var(--spacer-medium);
      }
    }
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-neutral-light);

    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      box-shadow: inset 0px -1px 0px var(--color-neutral);
      background: var(--color-neutral-lightest);
      flex-shrink: 0;

      &--mobile {
        padding: var(--spacer-smaller) 0;
      }
    }
  }
}

.page {
  width: calc(100% - 36px);
  max-width: 793px;
  height: 100%;
  position: relative;

  .inner-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--color-neutral-lightest);
    box-shadow: 0px 6px 10px rgba(130, 130, 140, 0.12);
    border-radius: var(--border-radius-large) var(--border-radius-large) 0px 0px;
    box-sizing: border-box;
    padding: 42px 35px 0 49px;
    overflow: hidden;
  }

  svg {
    box-sizing: content-box;
  }

  .table {
    width: 100%;
    border-spacing: 0;
    border: 2px solid var(--color-neutral);
    border-radius: 5px;
    table-layout: fixed;

    thead {
      background-color: var(--color-neutral-light);
      text-align: left;

      th {
        border-bottom: 1px solid var(--color-neutral);
        overflow: hidden;
      }

      th:nth-last-child(-n + 2) {
        width: 72px;
      }
    }

    tbody {
      td {
        border: 1px solid var(--color-neutral);
        overflow: hidden;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    tfoot td {
      text-align: right;
      border-top: 1px solid var(--color-neutral);
    }
  }
}
