.wrapper {
  background-color: var(--color-neutral-light);
  display: flex;
  flex: 1;

  .navigation {
    background-color: var(--color-menu-dark);
    width: 90px;

    .logo {
      width: 54px;
      height: 54px;
      border-radius: var(--border-radius-large);
      background-color: var(--color-primary);
      margin: var(--spacer-small) var(--spacer-regular);
    }
  }

  .header {
    background: var(--color-neutral-lightest);
    border-bottom: 1px solid var(--color-neutral);
    height: var(--header-height);
  }

  &--mobile {
    flex-direction: column;

    .navigation {
      height: 78px;
      width: 100%;
    }

    .header {
      height: var(--mobile-header-height);
    }
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-self: center;
  transform: translateY(-60px);
  margin: auto !important;

  .illustration {
    color: var(--color-primary);
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: var(--spacer-smaller);
    font-weight: 500;
  }

  .message,
  .contact {
    color: var(--color-neutral-darkest);
    line-height: 21px;
    text-align: center;
    max-width: 400px;
  }
}
