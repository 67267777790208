.loading-spinner {
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: var(--spinner-size-medium);
  width: var(--spinner-size-medium);

  &-small {
    height: var(--spinner-size-small);
    width: var(--spinner-size-small);
  }

  &:before {
    box-sizing: border-box;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
    width: 100%;

    border: var(--spinner-border-width) solid color-mod(var(--color-teal-darkest) a(18%));
    border-top-color: var(--color-teal-darkest);
  }

  &.is-inverse::before {
    border: var(--spinner-border-width) solid color-mod(var(--color-neutral-lightest) a(18%));
    border-top-color: var(--color-secondary);
  }
}
